.deebers.message {
  font-size: 14px;
  position: relative;
  min-height: 1em;
  margin: 1em 0;
  background: #f8f8f9;
  padding: 1em 1.5em;
  line-height: 1.4285em;
  color: rgba(0,0,0,.87);
  -webkit-transition: opacity .1s ease,color .1s ease,background .1s ease,-webkit-box-shadow .1s ease;
  transition: opacity .1s ease,color .1s ease,background .1s ease,-webkit-box-shadow .1s ease;
  transition: opacity .1s ease,color .1s ease,background .1s ease,box-shadow .1s ease;
  transition: opacity .1s ease,color .1s ease,background .1s ease,box-shadow .1s ease,-webkit-box-shadow .1s ease;
  -webkit-box-shadow: 0 0 0 1px rgba(34,36,38,.22) inset, 0 0 0 0 transparent;
  box-shadow: 0 0 0 1px rgba(34,36,38,.22) inset, 0 0 0 0 transparent;
}

.deebers.error.message {
  background-color: #ffe8e6;
  color: #db2828;
  -webkit-box-shadow: 0 0 0 1px #db2828 inset, 0 0 0 0 transparent;
  box-shadow: 0 0 0 1px #db2828 inset, 0 0 0 0 transparent;

  .header {
    color: #912d2b;
    font-weight: bold;
  }
  ul.list {
    text-align: left;
    padding: 0;
    opacity: .85;
    list-style-position: inside;
    margin: .5em 0 0;

    li{
      position: relative;
      list-style-type: disc;
      margin: 0 0 .3em 1em;
      padding: 0;
      list-style-position: inside;
    }
  }
}