/*=================
     
    contact.scss

=======================*/

.contact_wrap {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto 100px;
  padding: 50px 100px;
  background: #fff;
  -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 960px) {
  .contact_wrap {
    padding: 20px 12px;
  }
}

.contact_select_block {
  display: none;

  &.active {
    display: block;
  }

  .contact_block {
    border-bottom: 1px #d4d4d4 solid;

    &:last-of-type {
      border-bottom: 1px #d4d4d4 solid;
    }
  }
}

.contact_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  padding: 30px 0;
  border-bottom: 1px #d4d4d4 solid;

  &:last-of-type {
    border-bottom: none;
  }
}

@media only screen and (max-width: 960px) {
  .contact_block {
    padding: 20px 0;
  }
}

@media only screen and (max-width: 640px) {
  .contact_block {
    display: block;
  }
}

.contact_title {
  width: 200px;
  min-width: 200px;
  font-size: 18px;
  color: #242424;
  letter-spacing: 1.2px;
  font-weight: 900;
  padding: 15px 0 0;
}

@media only screen and (max-width: 960px) {
  .contact_title {
    font-size: 14px;
    width: 150px;
    min-width: 150px;
  }
}

@media only screen and (max-width: 640px) {
  .contact_title {
    width: 100%;
    min-width: inherit;
    margin: 0 0 10px;
    padding: 0;
  }
}

.contact_main {
  width: 100%;
}

.contact_complete_text {
  font-size: 16px;
  line-height: 2;
  letter-spacing: 1px;
  color: #242424;
  text-align: center;
  margin: 0 0 60px;
}

@media only screen and (max-width: 960px) {
  .contact_complete_text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 500px) {
  .contact_complete_text {
    font-size: 12px;
  }
}
