/* popup */

.modal_filter {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  z-index: 60;
  width: 100%;
  height: 100%;
  background: rgba(13, 26, 41, 0.35);
}

.modal_block {
  display: none;
  background: #fff;
  -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  max-width: 470px;
  width: 95%;
  z-index: 61;

  .modal_title {
    font-size: 16px;
    color: #242424;
    font-weight: 500;
    line-height: 1.5;
    margin: 0 0 15px;
    text-align: center;
  }

  .profile_drop_block {
    height: 500px;
    position: relative;
    width: 100%;
    border: 5px #D4D4D4 dashed;
    margin: 0 0 40px;
  }

  .profile_drop_inner {
    position: absolute;
    text-align: center;
    width: 95%;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .profile_upload_icon {
    display: block;
    width: 91px;
    margin: 0 auto 35px;
  }

  .profile_upload_textarea {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.2px;
  }

  .profile_upload_text {
    display: inline-block;
    color: #333;
  }

  .profile_upload_label {
    display: inline-block;
    color: #007ADF;
  }

  .icon_modal_close{
    width: 100%;
    margin-bottom: 10px;
    height: 20px;
  }
}

.booking_error_text{
  margin-bottom: 15px;
}

.btn_error_cancel{
  max-width: none;
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .modal_block {
  }
}

@media only screen and (max-width: 500px) {
  .modal_block {
    padding: 20px 12px;
  }
}

@media only screen and (max-width: 640px) {
  .modal_block .modal_title {
    font-size: 14px;
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 640px) {
  .modal_block .profile_drop_block {
    height: 250px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 640px) {
  .modal_block .profile_upload_icon {
    width: 65px;
    margin: 0 auto 20px;
  }
}

@media only screen and (max-width: 640px) {
  .modal_block .profile_upload_textarea {
    font-size: 14px;
  }
}