/*=================
     
    mailbox.scss

=======================*/

.mailbox_wrap {
  padding: 50px 0 165px;
}

@media only screen and (max-width: 960px) {
  .mailbox_wrap {
    padding: 30px 0 50px;
  }
}

.mailbox_search_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 270px;

  form {
    width: 100%;
  }

  .mailbox_search_input {
    outline: none;
    display: block;
    width: 100%;
    height: 50px;
    border: 1px #D4D4D4 solid;
    font-size: 14px;
    letter-spacing: 1px;
    color: #242424;
    padding: 0 12px 0 40px;
    background: url("/images/common/icon11.svg") no-repeat #fff;
    background-size: 20px;
    background-position: left 12px center;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  .mailbox_menu_btn {
    outline: none;
    height: 40px;
    width: 30px;
    min-width: 30px;
    margin: 0 15px 0 0;
    outline: none;
    border: none;
    background: none;
    padding: 0;
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_search_block {
    width: 100%;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_search_block .mailbox_search_input {
    font-size: 13px;
    height: 40px;
    background-size: 15px;
  }
}

.mailbox_pager_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  margin: 0 0 20px;

  .mailbox_pager_text {
    font-size: 12px;
    color: #A8A8A8;
    line-height: 1.5;
    margin: 0 20px 0 0;
  }

  .mailbox_prev {
    display: block;
    width: 9px;
    margin: 0 20px 0 0;

    img {
      display: block;
      width: 100%;
    }
  }

  .mailbox_next {
    display: block;
    width: 9px;

    img {
      display: block;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_pager_block {
    display: none;
  }
}

.mailbox_layout {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;

  .mailbox_menu {
    position: relative;
    display: block;
    width: 270px;
    min-width: 270px;
    background: #F9F9F9;
    padding: 10px 0 0;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &.active {
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  .mailbox_main {
    height: 640px;
    overflow-y: scroll;
    width: 100%;
    background: #fff;
    -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_layout {
    display: block;
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_layout .mailbox_menu {
    min-height: inherit;
    width: 100%;
    height: calc( 100% - 200px );
    max-height: 100%;
    position: fixed;
    z-index: 20;
    left: 0;
    bottom: 0;
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_layout .mailbox_main {
    height: inherit;
    padding: 0 0 20px;
  }
}

.mailbox_menu_close {
  display: block;
  position: fixed;
  top: -55px;
  left: 0;
  z-index: 20;
  width: 48px;
  height: 55px;
  padding: 0 0 15px 12px;
  border: none;
  background: #F9F9F9;
  outline: none;
}

.mailbox_menu_list {
  list-style: none;
  margin: 0;
  padding: 0;

  .mailbox_menu_item {
    margin: 0 0 10px;

    &.active {
      .mailbox_icon {
        .icon_grey {
          display: none;
        }

        .icon_green {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .mailbox_link {
        color: #00ECBC;

        &:before {
          content: "";
        }
      }
    }
  }

  .mailbox_link {
    padding: 5px 30px;
    position: relative;
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-size: 14px;
    letter-spacing: 1.2px;
    font-weight: bold;
    line-height: 1.2;

    &:before {
      content: none;
      height: 100%;
      width: 4px;
      height: 30px;
      background: #00ECBC;
      position: absolute;
      top: 50%;
      left: 0;
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    span {
      font-size: 12px;
      letter-spacing: 1px;
      padding: 4px 7px;
      background: #00ECBC;
      margin: 0 0 0 10px;
      color: #fff;
      -moz-border-radius: 15px;
      -webkit-border-radius: 15px;
      border-radius: 15px;
    }
  }

  .mailbox_icon {
    width: 20px;
    margin: 0 10px 0 0;
    text-align: center;

    .icon_grey {
      display: inline-block;
      vertical-align: middle;
    }

    .icon_green {
      display: none;
    }
  }
}

/* table */

.mailbox_table {
  width: 100%;

  tr {
    cursor: pointer;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &:hover {
      background: #f3f3f3;
    }

    &.hover_none {
      cursor: inherit;

      &:hover {
        background: #fff;
      }
    }
  }

  .mailbox_title {
    font-size: 12px;
    color: #A8A8A8;
    letter-spacing: 1.2px;
    line-height: 1.5;
    padding: 10px 20px;
    font-weight: 500;
    border-bottom: 1px #EBEBEB solid;

    &.company {
      width: 28%;
    }

    &.detail {
      width: 54%;
    }

    &.time {
      width: calc( 18% - 40px );
    }

    &.icon {
      width: 40px;
    }
  }

  .mailbox_detail {
    padding: 8px 20px;
    color: #242424;
    line-height: 1.5;
    font-size: 14px;

    &.btn {
      padding: 8px 5px;
    }
  }
}

.mailbox_active {
  .mailbox_company_text {
    font-weight: 900;
  }

  .mailbox_info_dot {
    display: block;
  }

  .mailbox_info_text, .mailbox_time {
    font-weight: 900;
  }
}

/* list */

.mailbox_list {
  margin: 0 0 10px;

  .mailbox_item {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    font-size: 11px;
    padding: 15px 12px;
    color: #242424;
    cursor: pointer;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    span {
      white-space: nowrap;
      margin: 0 5px 0 0;
      color: #959595;
    }

    &:hover {
      background: #f3f3f3;
    }

    &.active {
      font-weight: 900;

      .mailbox_info_dot {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .mailbox_list .mailbox_item:hover {
    background: #fff;
  }
}

.mailbox_item_main {
  width: calc( 100% - 45px );
}

/* mailbox */

.mailbox_company {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  span {
    white-space: nowrap;
    margin: 0 5px 0 0;
    color: #959595;
  }
}

.mailbox_company_icon {
  display: block;
  width: 30px;
  height: 30px;
  min-width: 30px;
  margin: 0 10px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #EBEBEB;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

@media only screen and (max-width: 960px) {
  .mailbox_company_icon {
    width: 35px;
    min-width: 35px;
    height: 35px;
  }
}

.mailbox_company_text {
  letter-spacing: 1px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

@media only screen and (max-width: 960px) {
  .mailbox_company_text {
    font-size: 13px;
    width: 100%;
  }
}

.mailbox_info {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.mailbox_info_dot {
  display: none;
  width: 10px;
  height: 10px;
  min-width: 10px;
  margin: 0 10px 0 0;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.mailbox_info_label {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  min-width: 70px;
  text-align: center;
  padding: 4px 12px;
  white-space: nowrap;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  margin: 0 15px 0 0;
}

@media only screen and (max-width: 960px) {
  .mailbox_info_label {
    min-width: 60px;
    font-size: 10px;
    padding: 6px;
    margin: 0 6px 0 0;
  }
}

.mailbox_info_text {
  letter-spacing: 1px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.mailbox_reply_icon {
  display: block;
  width: 19px;
  min-width: 19px;
  margin: 0 0 0 10px;
}

@media only screen and (max-width: 960px) {
  .mailbox_reply_icon {
    width: 14px;
    min-width: 14px;
    margin: 0 0 0 5px;
  }
}

.mailbox_time {
  white-space: nowrap;
}

@media only screen and (max-width: 960px) {
  .mailbox_time {
    width: 75px;
    min-width: 75px;
    text-align: right;
  }
}

.mailbox_delete_btn {
  padding: 0;
  display: block;
  width: 30px;
  background: none;
  outline: none;
  border: none;
  outline: none;
}

.mailbox_loadmore_btn {
  display: block;
  width: 30px;
  background: none;
  border: none;
  padding: 0;
  margin: 0 auto;
  outline: none;

  img {
    display: block;
    width: 100%;
  }
}

.mailbox_all_delete_btn {
  display: block;
  width: 200px;
  height: 40px;
  border: none;
  padding: 0;
  font-size: 12px;
  letter-spacing: 1.5px;
  font-weight: bold;
  color: #fff;
  margin: 0 0 15px 270px;
  text-decoration: none;
  text-align: center;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwN2FkZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzY4YjhmYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background: -moz-linear-gradient(0deg, #007adf, #68b8fa);
  background: -webkit-linear-gradient(0deg, #007adf, #68b8fa);
  background: linear-gradient(90deg, #007adf, #68b8fa);
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

@media only screen and (max-width: 960px) {
  .mailbox_all_delete_btn {
    max-width: 180px;
    width: 100%;
    margin: 0 0 15px;
  }
}

/* detail */

.mailbox_d_top_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  padding: 25px 30px;
  border-bottom: 1px #EBEBEB solid;

  .mailbox_top_user {
    margin: 0 20px 0 0;
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_d_top_block {
    display: block;
    padding: 20px 12px;
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_d_top_block .mailbox_top_user {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin: 0 0 10px;
  }
}

.mailbox_d_company_icon {
  display: block;
  width: 40px;
  height: 40px;
  min-width: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #EBEBEB;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

@media only screen and (max-width: 960px) {
  .mailbox_d_company_icon {
    width: 35px;
    min-width: 35px;
    height: 35px;
  }
}

.mailbox_d_title_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  line-height: 1.5;
  width: 100%;
  padding: 7px 0 0;
}

@media only screen and (max-width: 960px) {
  .mailbox_d_title_block {
    padding: 0;
  }
}

.mailbox_d_title {
  font-size: 18px;
  letter-spacing: 1.2px;
  color: #242424;
  font-weight: 900;
  margin: 0;
}

@media only screen and (max-width: 960px) {
  .mailbox_d_title {
    font-size: 14px;
    padding: 3px 0 0;
  }
}

.mailbox_d_date {
  padding: 12px 0 0;
  width: 125px;
  min-width: 125px;
  text-align: right;
  white-space: nowrap;
  font-size: 14px;
  color: #A8A8A8;
  font-weight: 500;
  margin: 0;
}

@media only screen and (max-width: 960px) {
  .mailbox_d_date {
    padding: 0;
    margin: 0 0 0 10px;
    font-size: 12px;
    width: inherit;
    min-width: inherit;
    text-align: left;
  }
}

.mailbox_d_main_block {
  padding: 30px;
  position: relative;
}

@media only screen and (max-width: 960px) {
  .mailbox_d_main_block {
    padding: 20px 12px;
  }
}

.mailbox_d_from {
  font-size: 14px;
  letter-spacing: 1.2px;
  font-weight: 900;
  color: #242424;
  margin: 0 0 30px;
  max-width: 690px;
  width: calc( 100% - 30px );
}

@media only screen and (max-width: 960px) {
  .mailbox_d_from {
    font-size: 13px;
    margin: 0 0 20px;
  }
}

.mailbox_d_delete_btn {
  position: absolute;
  top: 35px;
  right: 30px;
  outline: none;
  display: block;
  width: 20px;
  background: none;
  border: none;
  padding: 0;

  img {
    display: block;
    width: 16px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_d_delete_btn {
    top: 25px;
    right: 12px;
  }
}

.mailbox_d_editor {
  display: block;
  max-width: 690px;
  width: calc( 100% - 30px );
  color: #242424;
  line-height: 1.8;
  word-break: break-word;

  ul {
    padding: 0;
    list-style: none;
    margin: 0;
  }

  img {
    display: block;
    max-width: 100%;
  }
}

.mailbox_d_btnarea {
  padding: 30px;
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

@media only screen and (max-width: 960px) {
  .mailbox_d_btnarea {
    padding: 20px 12px;
  }
}

@media only screen and (max-width: 500px) {
  .mailbox_d_btnarea {
    display: block;
  }
}

.mailbox_common_btn01 {
  font-size: 14px;
  color: #fff;
  font-weight: 900;
  letter-spacing: 1.2px;
  width: 240px;
  height: 60px;
  border: none;
  padding: 0;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwN2FkZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzY4YjhmYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background: -moz-linear-gradient(0deg, #007adf, #68b8fa);
  background: -webkit-linear-gradient(0deg, #007adf, #68b8fa);
  background: linear-gradient(90deg, #007adf, #68b8fa);
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;

  img {
    display: inline-block;
    margin: 0 12px 0 0;
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_common_btn01 {
    font-size: 12px;
    height: 45px;
  }
}

@media only screen and (max-width: 500px) {
  .mailbox_common_btn01 {
    width: 100%;
  }
}

.mailbox_common_btn02 {
  border: none;
  padding: 0;
  outline: none;
  margin: 0 15px;
  font-size: 12px;
  font-weight: bold;
  color: #A8A8A8;
  background: none;
}

@media only screen and (max-width: 500px) {
  .mailbox_common_btn02 {
    display: block;
    margin: 0 20px;
  }
}

.mailbox_block_btn {
  border: none;
  padding: 0;
  outline: none;
  margin: 0 0 0 50px;
  font-size: 12px;
  font-weight: bold;
  color: #A8A8A8;
  background: none;

  img {
    display: inline-block;
    vertical-align: -1px;
    margin: 0 10px 0 0;
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_block_btn {
    margin: 0 0 0 20px;
  }
}

@media only screen and (max-width: 500px) {
  .mailbox_block_btn {
    display: block;
    width: 130px;
    margin: 20px auto 0;
  }
}

.mailbox_send_block {
  padding: 30px;

  .mailbox_send_inner {
    border: 1px #D4D4D4 solid;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  .mailbox_send_main {
    padding: 20px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_send_block {
    padding: 20px 12px;
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_send_block .mailbox_send_main {
    padding: 12px;
    margin: 0 0 15px;
  }
}

.mailbox_send_title_block {
  margin: 0 0 20px;
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;

  img {
    display: block;
    width: 19px;
    min-width: 19px;
    margin: 0 5px 0 0;
  }

  .mailbox_send_title {
    font-size: 12px;
    line-height: 1.5;
    color: #242424;
    margin: 0;
    font-weight: bold;
    letter-spacing: 1.2px;
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_send_title_block {
    margin: 0 0 15px;
  }
}

.mailbox_send_textarea {
  font-size: 12px;
  line-height: 1.5;
  color: #242424;
  resize: none;
  text-align: left;
  width: 100%;
  height: 210px;
  border: none;
  outline: none;
}

@media only screen and (max-width: 960px) {
  .mailbox_send_textarea {
    height: 150px;
  }
}

.mailbox_sned_btnarea {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 10px 20px;
  -moz-box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 960px) {
  .mailbox_sned_btnarea {
    padding: 10px 12px;
  }
}

@media only screen and (max-width: 500px) {
  .mailbox_sned_btnarea {
    display: block;
  }
}

.mailbox_send_btn {
  display: block;
  width: 100px;
  height: 40px;
  background: #007ADF;
  color: #fff;
  font-size: 12px;
  font-weight: 900;
  margin: 0 15px 0 0;
  border: none;
  padding: 0;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;

  img {
    display: inline-block;
    width: 12px;
    margin: 0 0 0 3px;
  }
}

@media only screen and (max-width: 500px) {
  .mailbox_send_btn {
    width: 100%;
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 500px) {
  .mailbox_send_bottom {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
}

.mailbox_dl {
  padding: 20px 30px;
  margin: 0;
  position: relative;
  border-bottom: 1px #EBEBEB solid;

  &:last-of-type {
    border-bottom: none;
  }

  .mailbox_dt.active {
    padding: 0 0 20px;

    .mailbox_dt_text {
      display: none;
    }
  }

  .mailbox_d_from {
    margin: 0 0 7px;
  }

  .mailbox_dt_text {
    font-size: 12px;
    color: #A8A8A8;
    letter-spacing: 1.2px;
    line-height: 1.8;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin: 0;
    width: calc( 100% - 30px );
  }

  .mailbox_dd {
    display: none;
    margin: 0;
  }

  .mailbox_dd_text {
    font-size: 12px;
    color: #242424;
    letter-spacing: 1.2px;
    line-height: 1.8;
    margin: 0;
    width: calc( 100% - 30px );
  }

  .mailbox_dd_subtext {
    font-size: 12px;
    color: #A8A8A8;
    letter-spacing: 1.2px;
    line-height: 1.8;
    margin: 0;
    width: calc( 100% - 50px );
    border-left: 2px rgba(0, 236, 188, 0.3) solid;
    padding: 0 0 0 20px;
    margin: 20px 0 0 20px;
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_dl {
    padding: 15px 12px;
  }
}

@media only screen and (max-width: 960px) {
  .mailbox_dl .mailbox_dd_subtext {
    margin: 15px 0 0 12px;
    padding: 0 0 0 12px;
  }
}
