/*=================
     
    custom select.scss

=======================*/
/** Custom Select **/

.custom-select-wrapper {
  position: relative;
  display: block;
  width: 100%;
  user-select: none;

  select {
    display: none;
  }
}

.custom-select {
  position: relative;
  display: block;
}

.custom-select-trigger {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 50px 0 15px;
  font-size: 14px;
  color: #333;
  line-height: 48px;
  background: #fff;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  border: 1px #D4D4D4 solid;
  cursor: pointer;
  letter-spacing: 1px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 20px;
    top: 50%;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url("/images/common/icon08.svg") center center no-repeat;
    background-size: contain;
    width: 14px;
    height: 9px;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

@media only screen and (max-width: 500px) {
  .custom-select-trigger {
    line-height: 38px;
  }
}

.custom-select.opened .custom-select-trigger:after {
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  min-width: 100%;
  margin: -7px 0 0;
  -moz-border-radius: 0 0 8px 8px;
  -webkit-border-radius: 0;
  border-radius: 0 0 8px 8px;
  box-sizing: border-box;
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(-15px);
}

.custom-select.opened .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translateY(0);
}

.custom-option {
  position: relative;
  display: block;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.5;
  color: #333;
  padding: 7px 15px;

  &:last-of-type {
    -moz-border-radius: 0 0 8px 8px;
    -webkit-border-radius: 0;
    border-radius: 0 0 8px 8px;
  }

  &:hover, &.selection {
    background: rgba(104, 184, 250, 0.2);
  }
}
