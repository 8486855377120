/*=================
     
      register.scss

=======================*/

.register_wrap {
  padding: 120px 12px;
  background: #F9F9F9;

  .register_inner {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
  }

  .register_sub {
    width: 50%;
    background: url("../images/register/bg02.jpg") center center no-repeat;
    background-size: cover;
    position: relative;
    height: 838px;

    .register_sub_inner {
      width: calc( 100% - 160px );
      position: absolute;
      top: 50%;
      left: 50%;
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }

    .register_sub_logo {
      margin: 0 auto;
      display: block;
      width: 100%;
      margin: 0 0 30px;
    }

    .register_sub_title {
      font-size: 96px;
      letter-spacing: -1.2px;
      line-height: 90px;
      color: #fff;
      margin: 0 0 10px;
    }

    .register_sub_text {
      font-size: 20px;
      letter-spacing: 2px;
      font-weight: 900;
      line-height: 1.5;
      color: #fff;
    }
  }

  .register_main {
    width: 60%;
    background: #fff;
    justify-content: space-between;

    .register_title {
      -js-display: flex;
      display: flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      font-size: 21px;
      font-weight: bold;
      color: #007ADF;
      letter-spacing: 2px;
      margin: 0 0 50px;

      img {
        display: block;
        width: 238px;
      }
    }

    .register_text {
      display: inline-block;
      line-height: 2.1;
      font-size: 16px;
      color: #242424;

      a {
        line-height: 1.5;
        display: inline-block;
        color: #007ADF;
        text-decoration: none;
        border-bottom: 1px #007ADF solid;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .register_wrap {
    padding: 60px 12px;
  }
}

@media only screen and (max-width: 750px) {
  .register_wrap .register_inner {
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .register_wrap .register_sub {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .register_wrap .register_sub .register_sub_inner {
    width: calc( 100% - 80px );
  }
}

@media only screen and (max-width: 960px) {
  .register_wrap .register_sub .register_sub_inner {
    width: calc( 100% - 40px );
  }
}

@media only screen and (max-width: 750px) {
  .register_wrap .register_sub .register_sub_inner {
    position: relative;
    top: inherit;
    left: inherit;
    -moz-transform: translateX(0) translateY(0);
    -ms-transform: translateX(0) translateY(0);
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1024px) {
  .register_wrap .register_sub .register_sub_title {
    font-size: 75px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 640px) {
  .register_wrap .register_sub .register_sub_title {
    font-size: 60px;
    line-height: 58px;
  }
}

@media only screen and (max-width: 640px) {
  .register_wrap .register_sub .register_sub_text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1024px) {
  .register_wrap .register_main {
    /* padding: 40px 40px 0; */
  }
}

@media only screen and (max-width: 960px) {
  .register_wrap .register_main {
    /* padding: 40px 20px; */
  }
}

@media only screen and (max-width: 750px) {
  .register_wrap .register_main {
    width: 100%;

    /* padding: 40px 12px; */
  }
}

@media only screen and (max-width: 960px) {
  .register_wrap .register_main .register_title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 750px) {
  .register_wrap .register_main .register_title {
    max-width: 500px;
    margin: 0 auto 25px;
  }
}

@media only screen and (max-width: 960px) {
  .register_wrap .register_main .register_title img {
    width: 180px;
  }
}

@media only screen and (max-width: 960px) {
  .register_wrap .register_main .register_text {
    font-size: 14px;
    line-height: 1.8;
  }
}

.register_complete_text {
  font-size: 21px;
  color: #007ADF;
  letter-spacing: 1.5px;
  font-weight: bold;
  margin: 0 0 220px;
}

@media only screen and (max-width: 750px) {
  .register_complete_text {
    margin: 0 auto 100px;
    font-size: 16px;
    max-width: 500px;
    width: 100%;
  }
}
