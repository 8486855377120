/*=================
     
      login.scss

=======================*/

.login_copyright {
  background: #242424;
  display: block;
  width: 100%;
  font-size: 20px;
  padding: 32px 12px;
  color: #fff;
  text-align: center;
  letter-spacing: 2px;
}

@media only screen and (max-width: 960px) {
  .login_copyright {
    font-size: 12px;
    padding: 20px 12px;
  }
}

.login_wrap {
  padding: 120px 12px 0;

  &.complete {
    height: calc( 100vh - 84px );
    min-height: 660px;

    .login_inner {
      height: 100%;
    }

    .login_block {
      width: 100%;
      padding: 0;
      position: absolute;
      top: 40%;
      left: 50%;
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

@media only screen and (max-width: 640px) {
  .login_wrap {
    padding: 60px 12px 0;
  }
}

@media only screen and (max-width: 960px) {
  .login_wrap.complete {
    height: calc( 100vh - 52px );
  }
}

@media only screen and (max-width: 640px) {
  .login_wrap.complete {
    min-height: inherit;
  }
}

.login_inner {
  position: relative;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.login_block {
  padding: 50px 0 100px;
}

@media only screen and (max-width: 640px) {
  .login_block {
    padding: 25px 0 50px;
  }
}

.login_logo {
  display: flex;
  max-width: 358px;
  width: 80%;
  align-self: center;
  margin: 36px 0;

  img {
    display: block;
    width: 100%;
  }
}

.login_title {
  text-align: center;
  font-size: 24px;
  letter-spacing: 1.5px;
  font-weight: 700;
  line-height: 1.5;
  color: #007ADF;
}

@media only screen and (max-width: 640px) {
  .login_title {
    font-size: 18px;
  }
}

.login_fb_btn {
  position: relative;
  display: block;
  width: 100%;
  max-width: 500px;
  height: 80px;
  line-height: 80px;
  font-size: 18px;
  color: #fff;
  background: #4267B2;
  font-weight: 900;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  margin: 0 auto 50px;

  .fb_icon {
    display: block;
    width: 33px;
    left: 25px;
    position: absolute;
    top: 50%;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .login_icon {
    display: block;
    width: 14px;
    position: absolute;
    top: 50%;
    right: 30px;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 640px) {
  .login_fb_btn {
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    margin: 0 auto 25px;
  }
}

@media only screen and (max-width: 640px) {
  .login_fb_btn .fb_icon {
    left: 10px;
  }
}

@media only screen and (max-width: 640px) {
  .login_fb_btn .login_icon {
    right: 15px;
  }
}

.login_other_text {
  font-size: 16px;
  color: #A8A8A8;
  letter-spacing: 1.5;
  line-height: 2;
  text-align: center;
  margin: 0 0 60px;
}

@media only screen and (max-width: 640px) {
  .login_other_text {
    font-size: 14px;
    margin: 0 0 30px;
  }
}

.login_input {
  display: block;
  max-width: 500px;
  width: 100%;
  border: none;
  border: 1px solid #656565;
  font-size: 16px;
  font-weight: 500;
  color: #242424;
  height: 46px;
  padding: 15px;
  outline: none;
  margin-top: 15px;
  margin-bottom: 5px;

  &:placeholder-shown, &::-webkit-input-placeholder {
    color: #242424;
  }

  &:-moz-placeholder, &::-moz-placeholder {
    color: #242424;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: #242424;
  }
}

@media only screen and (max-width: 640px) {
  .login_input, .label_input {
    /* margin: 0 auto 30px; */
  }
}

.login_reset_link {
  display: inline-block;
  font-size: 14px;
  color: #242424;
  line-height: 1.5;
  text-decoration: none;
  letter-spacing: 1.5px;
  padding: 0 0 5px;
  border-bottom: 1px #242424 solid;
}

@media only screen and (max-width: 640px) {
  .login_reset_link {
    font-size: 12px;
  }
}

.login_complete_text {
  font-size: 16px;
  line-height: 2;
  color: #242424;
  letter-spacing: 1.5px;
  margin: 0 0 80px;
  text-align: center;
}

@media only screen and (max-width: 640px) {
  .login_complete_text {
    font-size: 12px;
    margin: 0 0 40px;
  }
}
