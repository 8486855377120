/*=================
     
      common.scss

=======================*/
@media only screen and (max-width: 960px) {
  .common_main {
    padding: 60px 0 0;
  }
}

.common_main_before {
  padding: 120px 0 0;
}

@media only screen and (max-width: 960px) {
  .common_main_before {
    padding: 60px 0 0;
  }
}

/* margin */

.mb10 {
  margin: 0 0 10px;
}

.mb20 {
  margin: 0 0 20px;
}

@media only screen and (max-width: 960px) {
  .mb20 {
    margin: 0 0 12px;
  }
}

.mb30 {
  margin: 0 0 30px;
}

@media only screen and (max-width: 960px) {
  .mb30 {
    margin: 0 0 15px;
  }
}

.mb40 {
  margin: 0 0 40px;
}

@media only screen and (max-width: 960px) {
  .mb40 {
    margin: 0 0 20px;
  }
}

.mb50 {
  margin: 0 0 50px;
}

@media only screen and (max-width: 960px) {
  .mb50 {
    margin: 0 0 30px;
  }
}

.mb80 {
  margin: 0 0 80px;
}

@media only screen and (max-width: 960px) {
  .mb80 {
    margin: 0 0 40px;
  }
}

.mb100 {
  margin: 0 0 100px;
}

@media only screen and (max-width: 960px) {
  .mb100 {
    margin: 0 0 50px;
  }
}

.mb120 {
  margin: 0 0 120px;
}

@media only screen and (max-width: 960px) {
  .mb120 {
    margin: 0 0 60px;
  }
}

.w21 {
  width: 21%;
}

@media only screen and (max-width: 960px) {
  .pt100 {
    padding-top: 100px;
  }
}

/* align */

.align_center {
  text-align: center;
}

.align_right {
  text-align: right;
}

/* title */

.common_title01 {
  font-size: 60px;
  color: #242424;
  line-height: 1.5;

  span {
    display: block;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 1.3px;
  }

  &.center {
    text-align: center;
  }
}

@media only screen and (max-width: 960px) {
  .common_title01 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 960px) {
  .common_title01 span {
    font-size: 16px;
  }
}

.common_title02 {
  font-size: 24px;
  letter-spacing: 1.5px;
  color: #242424;
  font-weight: 900;
  line-height: 1.5;

  span {
    color: #00ECBC;
  }
}

@media only screen and (max-width: 960px) {
  .common_title02 {
    font-size: 18px;
  }
}

.common_title03 {
  font-size: 18px;
  letter-spacing: 1.5px;
  color: #242424;
  font-weight: 900;
  line-height: 1.5;
}

@media only screen and (max-width: 960px) {
  .common_title03 {
    font-size: 16px;
  }
}

.common_title04 {
  text-align: center;
  font-size: 36px;
  letter-spacing: 1.5px;
  font-weight: 700;
  line-height: 1.5;
  color: #007ADF;
}

@media only screen and (max-width: 960px) {
  .common_title04 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .common_title04 {
    font-size: 20px;
  }
}

/* button */

.common_btn01 {
  display: block;
  width: 100%;
  height: 80px;
  line-height: 78px;
  border: 1px #007ADF solid;
  font-size: 30px;
  color: #007ADF;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &:hover {
    color: #fff;
    background: #007ADF;
  }

  &.w400 {
    max-width: 400px;
  }

  &.center {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 960px) {
  .common_btn01 {
    height: 60px;
    line-height: 58px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 960px) {
  .common_btn01:hover {
    clear: #007ADF;
    background: none;
  }
}

.common_btn02 {
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #007ADF;
  color: #fff;
  font-size: 14px;
  letter-spacing: 1.5px;
  font-weight: bold;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  text-decoration: none;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  text-align: center;
  outline: none;
  border: none;

  &.w200 {
    max-width: 200px;
  }

  &.center {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 960px) {
  .common_btn02 {
    height: 45px;
    line-height: 45px;
    font-size: 12px;
  }
}

.common_btn03 {
  display: block;
  width: 100%;
  height: 50px;
  line-height: 48px;
  background: #fff;
  color: #007ADF;
  font-size: 14px;
  letter-spacing: 1.5px;
  font-weight: bold;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  text-decoration: none;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  text-align: center;
  outline: none;
  border: 1px #007ADF solid;

  &.center {
    margin: 0 auto;
  }

  &.w300 {
    max-width: 300px;
  }
}

@media only screen and (max-width: 960px) {
  .common_btn03 {
    height: 45px;
    line-height: 43px;
    font-size: 12px;
  }
}

.common_btn04 {
  outline: none;
  border: none;
  position: relative;
  display: block;
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-size: 18px;
  color: #fff;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwN2FkZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzY4YjhmYSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background: -moz-linear-gradient(0deg, #007adf, #68b8fa);
  background: -webkit-linear-gradient(0deg, #007adf, #68b8fa);
  background: linear-gradient(90deg, #007adf, #68b8fa);
  font-weight: 900;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  appearance: none;
  -webkit-appearance: none;

  &.center {
    margin: 0 auto;
  }

  &.header_sp {
    max-width: 360px;
  }

  &.w500 {
    max-width: 500px;
  }

  &:after {
    content: "";
    background: url("/images/common/icon01.svg") center center no-repeat;
    background-size: contain;
    height: 14px;
    width: 14px;
    position: absolute;
    top: 50%;
    right: 30px;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 640px) {
  .common_btn04 {
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 960px) {
  .common_btn04.header_sp {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    width: 250px;
    margin: 0;
  }
}

@media only screen and (max-width: 500px) {
  .common_btn04.header_sp {
    width: 165px;
    font-size: 11px;
    margin: 0 0 0 20px;
  }
}

@media only screen and (max-width: 500px) {
  .common_btn04.header_sp:after {
    height: 10px;
    width: 10px;
    right: 10px;
  }
}

@media only screen and (max-width: 640px) {
  .common_btn04:after {
    right: 15px;
  }
}

/* header type01 */

.header_type01 {
  .header_wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 120px;
    padding: 0 12px;
    z-index: 50;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &.active {
      background: rgba(255, 255, 255, 0.9);
    }

    .header_inner {
      height: 100%;
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
      -js-display: flex;
      display: flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

    .header_logo {
      display: block;
      width: 238px;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .header_nav {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    .header_list {
      -js-display: flex;
      display: flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      margin: 0;
      list-style: none;
      padding: 0;

      .header_item {
        margin: 0 50px 0 0;
      }

      .header_link {
        font-size: 16px;
        color: #242424;
        font-weight: 900;
        letter-spacing: 3px;
        text-decoration: none;
      }
    }

    .header_btn {
      display: block;
      position: relative;
      width: 200px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      text-decoration: none;
      font-size: 16px;
      letter-spacing: 3px;
      font-weight: 900;
      color: #fff;
      background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwZWNiYyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwN2FkZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
      background: -moz-linear-gradient(0deg, #00ecbc, #007adf);
      background: -webkit-linear-gradient(0deg, #00ecbc, #007adf);
      background: linear-gradient(90deg, #00ecbc, #007adf);

      img {
        display: block;
        width: 14px;
        position: absolute;
        top: 50%;
        right: 20px;
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }

  .header_sp_menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 45;
    background: #fff;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 960px) {
  .header_type01 .header_wrap {
    height: 60px;
  }
}

@media only screen and (max-width: 960px) {
  .header_type01 .header_wrap .header_logo {
    width: 180px;
  }
}

@media only screen and (max-width: 960px) {
  .header_type01 .header_nav {
    display: block;
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
}

@media only screen and (max-width: 960px) {
  .header_type01 .header_nav .header_list {
    display: block;
  }
}

@media only screen and (max-width: 960px) {
  .header_type01 .header_nav .header_list .header_item {
    margin: 0 0 40px;
    text-align: center;
  }
}

@media only screen and (max-width: 960px) {
  .header_type01 .header_nav .header_btn {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    height: 60px;
    line-height: 60px;
  }
}

.header_menu_btn {
  height: 40px;
  width: 40px;
  outline: none;
  border: none;
  background: none;
  padding: 0;

  &.white {
    .top, .middle, .bottom {
      background: #fff;
    }
  }

  .top, .middle {
    display: block;
    width: 30px;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwZWNiYyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwN2FkZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background: -moz-linear-gradient(0deg, #00ecbc, #007adf);
    background: -webkit-linear-gradient(0deg, #00ecbc, #007adf);
    background: linear-gradient(90deg, #00ecbc, #007adf);
    height: 2px;
    margin: 0 auto 5px;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }

  .bottom {
    width: 30px;
    display: block;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwZWNiYyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwN2FkZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background: -moz-linear-gradient(0deg, #00ecbc, #007adf);
    background: -webkit-linear-gradient(0deg, #00ecbc, #007adf);
    background: linear-gradient(90deg, #00ecbc, #007adf);
    height: 2px;
    margin: 0 auto;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }

  &.active {
    .top {
      -moz-transform: translateX(-1px) translateY(7px) rotate(45deg);
      -ms-transform: translateX(-1px) translateY(7px) rotate(45deg);
      -webkit-transform: translateX(-1px) translateY(7px) rotate(45deg);
      transform: translateX(-1px) translateY(7px) rotate(45deg);
    }

    .middle {
      opacity: 0;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

    .bottom {
      -moz-transform: translateX(-1px) translateY(-7px) rotate(-45deg);
      -ms-transform: translateX(-1px) translateY(-7px) rotate(-45deg);
      -webkit-transform: translateX(-1px) translateY(-7px) rotate(-45deg);
      transform: translateX(-1px) translateY(-7px) rotate(-45deg);
    }
  }
}

/* header type02 */

.header_type02 {
  .header_wrap {
    height: 86px;
    width: 100%;
    background-color: #0d1a29;
    background-size: cover;
    padding: 0 12px;
  }

  .header_inner {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
  }

  .header_top {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    height: 86px;

    .header_logo {
      display: block;
      width: 43px;
      margin: auto;

      img {
        display: block;
        width: 100%;
        min-width: 24px;
      }
    }

    .header_info {
      -js-display: flex;
      display: flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  }

  .header_user {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    font-size: 18px;
    color: #b0b0b0;
    font-weight: 500;

    img {
      display: block;
      width: 15px;
      margin: 0 10px 0 0;
    }
  }

  .header_logout_btn {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: flex-start;
    display: inline-flex;
    align-items: center;
    margin: 0 auto;
    background: none;
    outline: none;
    border: none;
    margin: 0 0 0 30px;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    img {
      margin-right: 10px;
    }
  }

  .header_nav {
    height: 80px;
  }

  .header_list {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 100%;
    margin: 0;
    list-style: none;
    padding: 0;

    .header_item {
      margin: 0 100px 0 0;

      &.no_active {
        display: none;
      }
    }

    .header_link {
      position: relative;
      display: inline-block;
      line-height: 80px;
      font-size: 20px;
      letter-spacing: 1.5px;
      color: #fff;
      font-weight: 900;
      text-decoration: none;

      &:after {
        content: none;
        display: block;
        height: 5px;
        background: #E300EC;
        width: 100%;
        position: absolute;
        bottom: -3px;
        left: 0;
      }

      &.active:after {
        content: "";
      }
    }
  }

  .header_sp_menu {
    display: none;
    text-align: center;
    position: fixed;
    right: 0;
    top: 60px;
    width: 55%;
    z-index: 55;
    background: #0d1a28;
    padding: 15px 25px;
  }

  .header_sp_list {
    margin: 0 0 80px;
    padding: 0;
    list-style: none;

    .header_item {
      margin: 0 0 30px;

      &:last-of-type {
        margin: 0;
      }
    }

    .header_link {
      display: inline-block;
      font-size: 16px;
      letter-spacing: 1.5px;
      line-height: 1.5;
      color: #fff;
      font-weight: 900;
      text-decoration: none;
      padding: 0 0 5px;

      &.active {
        border-bottom: 3px #fff solid;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .header_type02 .header_wrap {
    background-color: #0d1a29;
    background-size: cover;
    height: 60px;
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media only screen and (max-width: 640px) {
  .header_type02 .header_wrap {
    height: 50px;
  }
}

@media only screen and (max-width: 960px) {
  .header_type02 .header_top {
    height: 60px;
  }
}

@media only screen and (max-width: 640px) {
  .header_type02 .header_top {
    .header_logo {
      width: 24px;
    }

    height: 50px;
  }
}

@media only screen and (max-width: 960px) {
  .header_type02 .header_user {
    font-size: 14px;
    margin: 0 0 16px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 960px) {
  .header_type02 .header_user img {
    width: 12px;
  }
}

@media only screen and (max-width: 960px) {
  .header_type02 .header_logout_btn {
    width: 100%;
    color: #ffffff;
    font-family: "Futura LT";
    font-size: 14px;
    font-weight: 500;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: flex-start;
    display: inline-flex;
    align-items: center;
    margin: 0 auto;

    img {
      margin-right: 10px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .header_type02 .header_nav {
    height: 60px;
  }
}

@media only screen and (max-width: 960px) {
  .header_type02 .header_list .header_item {
    margin: 0;
  }
}

@media only screen and (max-width: 960px) {
  .header_type02 .header_list .header_link {
    line-height: 60px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 960px) {
  .header_type02 .header_list .header_link:after {
    height: 3px;
    bottom: -1px;
  }
}

@media only screen and (max-width: 640px) {
  .header_type02 .header_sp_menu {
    top: 50px;
  }
}

/* footer */

.footer_wrap {
  background: #ececec;
  padding: 35px 0 35px;

  &.pd_small {
    padding: 30px 0;
  }

  .footer_list {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;

    .footer_item {
      margin: 0 75px;
    }

    .footer_link {
      font-size: 14px;
      font-weight: 500;
      color: #656565;
      text-decoration: none;
      white-space: nowrap;
    }
  }
}

@media only screen and (max-width: 960px) {
  .footer_wrap.pd_small {
    padding: 20px 0;
  }
}

@media only screen and (max-width: 960px) {
  .footer_wrap {
    padding: 40px 0 35px;
  }
}

@media only screen and (max-width: 960px) {
  .footer_wrap .footer_list {
    margin: 0 0;
  }
}

@media only screen and (max-width: 320px) {
  .footer_wrap .footer_list {
    display: block;
  }
}

@media only screen and (max-width: 960px) {
  .footer_wrap .footer_list .footer_item {
    margin: 0 15px;
  }
}

@media only screen and (max-width: 320px) {
  .footer_wrap .footer_list .footer_item {
    margin: 0 0 15px;
    text-align: center;
  }
}

@media only screen and (max-width: 960px) {
  .footer_wrap .footer_list .footer_link {
    font-size: 14px;
  }
}

.footer_contact_btn {
  position: relative;
  display: block;
  width: 100%;
  max-width: 400px;
  height: 80px;
  line-height: 80px;
  font-size: 18px;
  color: #007ADF;
  background: #fff;
  font-weight: 900;
  text-align: center;
  text-decoration: none;
  letter-spacing: 2px;
  margin: 0 auto 100px;

  img {
    display: block;
    width: 14px;
    position: absolute;
    top: 50%;
    right: 30px;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 960px) {
  .footer_contact_btn {
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    margin: 0 auto 50px;
  }
}

@media only screen and (max-width: 960px) {
  .footer_contact_btn img {
    right: 15px;
  }
}

.footer_logo {
  display: block;
  width: 240px;
  margin: 0 auto 20px;

  img {
    display: block;
    width: 100%;
  }
}

@media only screen and (max-width: 960px) {
  .footer_logo {
    width: 200px;
  }
}

.footer_copyright {
  display: block;
  width: 100%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  letter-spacing: 2px;
}

@media only screen and (max-width: 960px) {
  .footer_copyright {
    font-size: 12px;
  }
}

/* column */

.common_column_list01 {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto 20px;

  .common_column_item {
    width: calc( 50% - 30px );
    height: 400px;
    margin: 0 0 60px;
  }

  .common_column_link {
    padding: 40px;
  }

  .common_column_box {
    bottom: 40px;
    width: calc( 100% - 80px );
  }
}

@media only screen and (max-width: 960px) {
  .common_column_list01 .common_column_item {
    width: calc( 50% - 10px );
    height: 300px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 640px) {
  .common_column_list01 .common_column_item {
    width: 100%;
  }
}

@media only screen and (max-width: 960px) {
  .common_column_list01 .common_column_link {
    padding: 20px;
  }
}

@media only screen and (max-width: 960px) {
  .common_column_list01 .common_column_box {
    bottom: 20px;
    width: calc( 100% - 40px );
  }
}

.common_column_list02 {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  list-style: none;
  padding: 0;

  .common_column_item {
    width: calc( ( 100% - 30px ) / 3 );
    height: 300px;
    margin: 0 15px 15px 0;

    &:nth-of-type(3n) {
      margin: 0 0 15px;
    }
  }

  .common_column_link {
    padding: 20px;
  }

  .common_column_box {
    bottom: 20px;
    width: calc( 100% - 40px );
  }
}

@media only screen and (max-width: 960px) {
  .common_column_list02 .common_column_item {
    width: calc( 50% - 10px );
    height: 300px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 640px) {
  .common_column_list02 .common_column_item {
    width: 100%;
  }
}

@media only screen and (max-width: 960px) {
  .common_column_list02 .common_column_box {
    bottom: 10px;
    width: calc( 100% - 20px );
  }
}

.common_column_link {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;

  &:after {
    content: "";
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }

  .common_column_date {
    position: relative;
    z-index: 2;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.5px;
    color: #fff;
  }

  .common_column_box {
    z-index: 2;
    position: absolute;
    left: 50%;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .common_column_title {
    font-size: 24px;
    color: #fff;
    font-weight: 700;
    line-height: 1.6;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}

@media only screen and (max-width: 960px) {
  .common_column_link .common_column_date {
    font-size: 14px;
  }
}

@media only screen and (max-width: 960px) {
  .common_column_link .common_column_title {
    font-size: 18px;
  }
}

.common_column_category {
  word-break: break-word;
  display: inline-block;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 3px;
  background: #00ECBC;
  color: #fff;
  padding: 10px;
  line-height: 1.4;
  margin: 0 0 20px;
}

@media only screen and (max-width: 960px) {
  .common_column_category {
    font-size: 10px;
    padding: 5px 10px;
  }
}

.common_column_rank {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 2;
  width: 40px;
  height: 40px;
  font-size: 20px;
  background: rgba(255, 255, 255, 0.9);
  color: #242424;
  font-weight: 900;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  &.big {
    width: 50px;
    height: 50px;
    font-size: 25px;
  }
}

/* cv */

.common_cv_block {
  background: url("/images/top/bg03.jpg") center center no-repeat;
  background-size: cover;
  padding: 70px 0;
  text-align: center;

  .common_cv_title {
    font-size: 48px;
    color: #fff;
    line-height: 1.5;
    font-weight: 900;
    letter-spacing: 1.5px;
    margin: 0 0 25px;
  }

  .common_cv_text {
    font-size: 16px;
    letter-spacing: 1.5px;
    color: #fff;
    line-height: 2;
    font-weight: 500;
    margin: 0 0 60px;
  }
}

@media only screen and (max-width: 960px) {
  .common_cv_block {
    padding: 35px 0;
  }
}

@media only screen and (max-width: 960px) {
  .common_cv_block .common_cv_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 960px) {
  .common_cv_block .common_cv_text {
    font-size: 12px;
  }
}

.common_cv_btn {
  display: block;
  position: relative;
  max-width: 400px;
  width: 100%;
  height: 80px;
  line-height: 80px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  letter-spacing: 3px;
  font-weight: 900;
  color: #fff;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuMCIgeTE9IjAuNSIgeDI9IjEuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwZWNiYyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwN2FkZiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background: -moz-linear-gradient(0deg, #00ecbc, #007adf);
  background: -webkit-linear-gradient(0deg, #00ecbc, #007adf);
  background: linear-gradient(90deg, #00ecbc, #007adf);

  &.center {
    margin: 0 auto;
  }

  img {
    display: block;
    width: 14px;
    position: absolute;
    top: 50%;
    right: 30px;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 960px) {
  .common_cv_btn {
    height: 60px;
    line-height: 60px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 960px) {
  .common_cv_btn img {
    right: 15px;
  }
}

/* form */

.common_select {
  outline: none;
  display: block;
  width: 100%;
  height: 50px;
  font-size: 14px;
  color: #333;
  letter-spacing: 1px;
  border: 1px #D4D4D4 solid;
  appearance: none;
  -webkit-appearance: none;
  background: url("/images/common/icon08.svg") no-repeat #fff;
  background-size: 14px;
  background-position: right 15px center;
  padding: 0 40px 0 15px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;

  &.w500 {
    max-width: 500px;
  }
}

@media only screen and (max-width: 640px) {
  .common_select {
    padding: 0 40px 0 10px;
  }
}

.common_input {
  outline: none;
  display: block;
  width: 100%;
  height: 50px;
  font-size: 14px;
  color: #333;
  letter-spacing: 1px;
  padding: 0 15px;
  border: 1px #D4D4D4 solid;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;

  &.w300 {
    max-width: 300px;
  }
}

@media only screen and (max-width: 640px) {
  .common_input {
    padding: 0 10px;
  }
}

.common_textarea {
  outline: none;
  display: block;
  width: 100%;
  height: 50px;
  font-size: 14px;
  color: #333;
  letter-spacing: 1px;
  padding: 15px;
  resize: none;
  height: 150px;
  border: 1px #D4D4D4 solid;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;

  &.w900 {
    max-width: 900px;
  }

  &.h350 {
    height: 350px;
  }

  &.center {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 640px) {
  .common_textarea {
    padding: 10px;
  }
}

@media only screen and (max-width: 960px) {
  .common_textarea.h350 {
    height: 250px;
  }
}

.common_pager_block {
  -js-display: flex;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;

  .common_pager_perv {
    display: block;
    width: 9px;
    margin: 0 20px 0 0;

    img {
      display: block;
      width: 100%;
    }
  }

  .common_pager_next {
    display: block;
    width: 9px;
    margin: 0 0 0 20px;

    img {
      display: block;
      width: 100%;
    }
  }

  .common_pager_list {
    -js-display: flex;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    .common_pager_item {
      margin: 0 10px;
    }

    .common_pager_link {
      text-decoration: none;
      font-size: 15px;
      color: #d4d4d4;
      font-weight: bold;

      &.active {
        color: #007ADF;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .common_pager_block .common_pager_list .common_pager_item {
    margin: 0 15px;
  }
}


.footer-room{
  position: fixed;
  bottom: 0;
  color: white;
  padding: 20px;
  background: #0d1a29;
  line-height: 20px;
  width: 100%;
  .footer-room__container{
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
  }
}