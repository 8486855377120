/*=================
     
      top.scss

=======================*/

.top_mv_block {
  height: 100vh;
  min-height: 760px;
  width: 100%;
  padding: 0 12px;
  position: relative;
  background: url("/images/top/bg01.jpg") center center no-repeat;
  background-size: cover;

  .top_mv_inner {
    max-width: 1140px;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 55%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .top_mv_subtitle {
    font-size: 240px;
    color: #007ADF;
    letter-spacing: -1px;
    line-height: 220px;
    margin: 0 0 50px;
  }

  .top_mv_title {
    display: inline-block;
    font-size: 40px;
    letter-spacing: 5px;
    font-weight: 900;
    line-height: 1.5;
    padding: 0 0 12px;
    border-bottom: 5px #007ADF solid;
    color: #007ADF;
  }
}

@media only screen and (max-width: 840px) {
  .top_mv_block {
    min-height: inherit;
  }
}

@media only screen and (max-width: 1024px) {
  .top_mv_block .top_mv_subtitle {
    font-size: 180px;
    line-height: 160px;
  }
}

@media only screen and (max-width: 840px) {
  .top_mv_block .top_mv_subtitle {
    font-size: 100px;
    line-height: 90px;
  }
}

@media only screen and (max-width: 640px) {
  .top_mv_block .top_mv_subtitle {
    font-size: 70px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 1024px) {
  .top_mv_block .top_mv_title {
    font-size: 25px;
  }
}

@media only screen and (max-width: 840px) {
  .top_mv_block .top_mv_title {
    letter-spacing: 3px;
    font-size: 18px;
    padding: 0 0 8px;
    border-bottom: 3px #007ADF solid;
  }
}

.top_about_block {
  height: 1080px;
  width: 100%;
  position: relative;
  background: url("/images/top/bg02.jpg") center center no-repeat;
  background-size: cover;
  padding: 0 8px;

  .top_about_inner {
    display: block;
    background: rgba(255, 255, 255, 0.95);
    max-width: 900px;
    width: 100%;
    height: 100%;
    padding: 300px 0 0;
    position: absolute;
    top: 0;
    left: 50%;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
  }

  .top_about_pic {
    position: absolute;
    z-index: 1;
    width: 1095px;
    top: 20px;
    left: 50%;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .top_about_title {
    font-size: 40px;
    letter-spacing: 1.5px;
    color: #242424;
    font-weight: 900;

    &:after {
      content: "";
      display: block;
      width: 80px;
      height: 5px;
      background: #242424;
      margin: 50px auto 60px;
    }
  }

  .top_about_text {
    font-size: 16px;
    color: #242424;
    line-height: 2;
    font-weight: 500;
  }
}

@media only screen and (max-width: 960px) {
  .top_about_block {
    height: inherit;
  }
}

@media only screen and (max-width: 960px) {
  .top_about_block .top_about_inner {
    margin: 0 auto;
    height: inherit;
    padding: 100px 8px;
    position: relative;
    top: inherit;
    left: inherit;
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media only screen and (max-width: 1200px) {
  .top_about_block .top_about_pic {
    width: 900px;
  }
}

@media only screen and (max-width: 960px) {
  .top_about_block .top_about_pic {
    max-width: 900px;
    width: 90%;
    top: 10px;
  }
}

@media only screen and (max-width: 960px) {
  .top_about_block .top_about_title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 960px) {
  .top_about_block .top_about_title:after {
    margin: 25px auto 30px;
    height: 3px;
    width: 60px;
  }
}

@media only screen and (max-width: 960px) {
  .top_about_block .top_about_text {
    font-size: 12px;
  }
}

.top_point_block {
  padding: 220px 0 190px;
  background: #F9F9F9;

  .top_point_article {
    position: relative;
    margin: 0 0 150px;

    &:last-of-type {
      margin: 0;
    }

    &.reverse {
      text-align: right;

      .top_point_box {
        right: inherit;
        left: 0;
        padding: 90px 75px 105px 100px;
      }
    }
  }

  .top_point_pic {
    display: inline-block;
    max-width: 980px;
    width: 80%;
    padding: 50px 0 0;
  }

  .top_point_box {
    text-align: left;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    background: #fff;
    width: 720px;
    padding: 90px 100px 105px 100px;
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  }

  .top_point_title {
    font-size: 120px;
    color: #242424;
    line-height: 1;

    &:after {
      content: "";
      display: block;
      width: 100px;
      height: 5px;
      margin: 35px 0 60px;
      background: #00ECBC;
    }
  }

  .top_point_subtitle {
    font-size: 28px;
    letter-spacing: 1.5px;
    color: #242424;
    font-weight: 700;
    line-height: 1.5;
    margin: 0 0 30px;
  }

  .top_point_text {
    font-size: 16px;
    font-weight: 500;
    color: #242424;
    line-height: 2;
    letter-spacing: 1px;
  }
}

@media only screen and (max-width: 960px) {
  .top_point_block {
    padding: 110px 0 90px;
  }
}

@media only screen and (max-width: 960px) {
  .top_point_block .top_point_article {
    margin: 0 0 70px;
  }
}

@media only screen and (max-width: 1024px) {
  .top_point_block .top_point_article.reverse .top_point_box {
    padding: 45px 20px;
  }
}

@media only screen and (max-width: 640px) {
  .top_point_block .top_point_article.reverse .top_point_box {
    left: inherit;
  }
}

@media only screen and (max-width: 640px) {
  .top_point_block .top_point_pic {
    width: 100%;
    padding: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .top_point_block .top_point_box {
    padding: 45px 20px;
    max-width: 720px;
    width: 80%;
  }
}

@media only screen and (max-width: 640px) {
  .top_point_block .top_point_box {
    width: calc( 100% - 24px );
    position: relative;
    top: inherit;
    right: inherit;
    margin: -100px auto 0;
    padding: 30px 12px;
  }
}

@media only screen and (max-width: 960px) {
  .top_point_block .top_point_title {
    font-size: 60px;
  }
}

@media only screen and (max-width: 960px) {
  .top_point_block .top_point_title:after {
    width: 60px;
    height: 3px;
    margin: 20px 0 30px;
  }
}

@media only screen and (max-width: 960px) {
  .top_point_block .top_point_subtitle {
    font-size: 18px;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 640px) {
  .top_point_block .top_point_subtitle {
    font-size: 16px;
  }
}

@media only screen and (max-width: 960px) {
  .top_point_block .top_point_text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 640px) {
  .top_point_block .top_point_text {
    font-size: 12px;
  }
}

.top_company_block {
  padding: 80px 0;
  text-align: center;

  .top_company_title {
    font-size: 28px;
    line-height: 1.5;
    color: #242424;
    font-weight: 700;
    margin: 0 0 50px;
  }

  .top_company_pic {
    display: inline-block;
    max-width: 814px;
    width: 100%;
  }
}

@media only screen and (max-width: 960px) {
  .top_company_block {
    padding: 40px 0;
  }
}

@media only screen and (max-width: 960px) {
  .top_company_block .top_company_title {
    font-size: 18px;
    margin: 0 0 30px;
  }
}

.top_column_block {
  padding: 80px 0 130px;
  background: #F9F9F9;
}

@media only screen and (max-width: 960px) {
  .top_column_block {
    padding: 40px 0 60px;
  }
}
